<template>
  <div class="card">
    <!-- <div class="card-header pt-10 pb-10">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ข้อมูลผู้ใช้งาน / Profile
      </h3>
      <div class="card-toolbar"></div>
    </div>

    <div class="card-title row ms-6 me-5 mt-10">
      <div class="symbol symbol-100px symbol-circle mb-10">
        <img class="shadow-sm" alt="Logo" src="media/avatars/150-1.jpg" />
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">Username</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.username }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">ชื่อผู้ใช้งาน</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.firstName }} {{ this.user.lastName }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">Email</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.email }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">เบอร์โทร</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.telNumber }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">บริษัท</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div>{{ this.user.company ? this.user.company.company_name : "" }}</div>
      </div>

      <div class="col-4 col-md-2 col-sm-1 mb-10">
        <div style="font-weight: bold; font-size: 14px">บทบาท</div>
      </div>
      <div class="col-1 col-sm-1">
        <div>:</div>
      </div>
      <div class="col-7 col-md-9 col-sm-10 text-start">
        <div :class="classRole">
          {{ this.user.role }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";

export default {
  data: () => ({
    userId: "",
    user: [],
    classRole: "badge badge-lg badge-light-success fw-bolder fs-6",
  }),
  created() {
    this.getAllUser();
  },
  methods: {
    async getAllUser() {
      this.userId = Decode.decode(localStorage.getItem("boss"));
      let responseData = [];

      try {
        responseData = await userApi.user.getOne(this.userId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.user = responseData.data;
        this.setClassRole();
      }
    },

    setClassRole() {
      this.user["role"] === "SUPER ADMIN"
        ? (this.classRole = "badge badge-lg badge-light-primary fw-bolder fs-6")
        : this.user["role"] === "ADMIN"
        ? (this.classRole = "badge badge-lg badge-light-success fw-bolder fs-6")
        : this.user["role"] === "MANAGER"
        ? (this.classRole = "badge badge-lg badge-light-warning fw-bolder fs-6")
        : this.user["role"] === "BRANCH MANAGER"
        ? (this.classRole = "badge badge-lg badge-light-danger fw-bolder fs-6")
        : this.user["role"] === "STAFF"
        ? (this.classRole = "badge badge-lg badge-light-info fw-bolder fs-6")
        : (this.classRole =
            "badge badge-lg badge-light-success fw-bolder fs-6");
    },
  },
};
</script>
